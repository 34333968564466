@media print {
  @page {
    size: 1100px;
    margin: 0;
  }
  #tab-container {
    margin-top: 20px;
    padding: 20px;
    border: none;
  }
  #report-print-button {
    display: none;
  }

  #tab_navigation_container {
    display: none;
  }
}

