#supply-analysis-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-table-thead .ant-table-cell {
  background: #4E4E4E !important;
  color: #FFFFFF !important;
  font-weight: 700;
  font-size: 14px;
  padding: 18px !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
}

.ant-pagination {
  margin-top: 30px !important;
}

.ant-table-container {
  border: 1px solid #DBDDE1;
  border-radius: 10px;
  border-top: none;
}

.ant-table-tbody .ant-table-cell {
  font-size: 16px;
  color: #4E4E4E !important;
  font-weight: 400;
  border-bottom-color: #DBDDE1 !important;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-pagination-item {
  font-size: 16px;
  color: #808080 !important;
  font-weight: 400;
}

.ant-pagination-item-active {
  border: none !important;
  background: #017DFD !important;
  color: #FFFFFF !important;
  font-size: 16px;
  font-weight: 700;
}

.ant-table-tbody .ant-table-cell-row-hover {
  background: #E6F3FD !important;
}
.ant-table-tbody .ant-table-row {
  cursor: pointer;
}
.ant-table-tbody .ant-table-row:hover .ant-table-cell:nth-child(2) {
  color: #017DFD !important;
  font-weight: 700;
}
.ant-table-tbody .ant-table-row:last-child .ant-table-cell {
  border-bottom: none !important;
}

@media print {
  #trading-table-container {
    page-break-before: always;
  }
}

