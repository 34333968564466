
#table-container {

  .ant-table-thead {
    .ant-table-cell {
      background: #495056 !important;
      color: #FFFFFF !important;
      font-weight: 700;
      font-size: 14px;
      padding: 18px !important;
      border-right: none !important;
      border-left: none !important;
      border-top: none !important;

    }
  }

  .ant-table-tbody > tr:nth-child(odd) > td {
    background: #F1F3F4; // 홀수 행의 배경색
  }

  .ant-table-tbody > tr:nth-child(even) > td {
    background: #ffffff; // 짝수 행의 배경색
  }

  .ant-pagination-item-active {
    border: none !important;
    background: #495056 !important;
    color: #FFFFFF !important;
    font-size: 16px;
    font-weight: 700;
  }
}