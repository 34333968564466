#income-trend-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

@media print {
  #income-trend-container {
    width: 1060px;
  }
  #income_trend_title {
    margin-top: 20px
  }
  #market-trend-title {
    page-break-before: always;
    margin-top: 40px;
  }
}
