#content-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

@media print {
  #company-title-layout {
    margin-top: 60px;
  }
  #supply-analysis-graph-chart {
    page-break-before: always;
    margin-top: 60px;
    height: fit-content;
  }
  #report-print-button {
    display: none;
  }
  #content-container {
    zoom: 80%
  }
}