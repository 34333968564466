.carousel-indicators [data-bs-target] {
    border: 1px solid #DBDDE1 !important;
    border-radius: 10px;
    width: max(1.5vw, 20px);
    margin-bottom: 10px;
}

.carousel-indicators .active {
    border: 1px solid #279EFF !important;
}

