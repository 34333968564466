@import "../Common.css";

.PreparePage-container {
    position: relative;
    height: 100%;
    min-height: 400px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.PreparePage-content-container {
    align-self: center;
    display: flex;
    flex-direction: column;
}

.PreparePage-title {
    font-weight: 700;
    font-size: 36px;
    color: #2F2F2F;
}

.PreparePage-subtitle {
    margin-top: 28px;
    font-size: 20px;
    color: #2F2F2F;
}

.PreparePage-button {
    margin-top: 44px;
    padding: 14px 32px;
    width: fit-content;
    display: flex;
    align-self: center;
    justify-content: center;
    font-weight: 700;
    font-size: 28px;
    background: #E6E8EA;
    border-radius: 10px;
    color: #3B6BB1;
}


/* MOBILE */
@media (max-width: 767px) {

    .PreparePage-title {
        font-size: 24px;
    }

    .PreparePage-subtitle {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 16px;
    }

    .PreparePage-button {
        font-size: 18px;
    }
}