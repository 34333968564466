#faq-container {
  .benefit-item {
    justify-content: center;

    @media (min-width: 992px) {
      justify-content: start;
    }
  }

  .header-nav-item {
    color: #ffffff !important;
  }

  .accordion-item {
    border: 1px solid #fff;

    & + .accordion-item {
      margin-top: 20px;
    }
  }

  .accordion-button {
    font-size: 22px;
    outline: none;

    &:focus {
      outline: none;
      box-shadow: none; // 추가된 부분
    }

    &:not(.collapsed) {
      background-color: #017dfd !important;
      color: white !important;

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }

    &.collapsed:hover::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23017DFD'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    &:hover {
      color: #017dfd !important;
    }

    &:hover:not(.collapsed) {
      color: white !important;
    }

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .accordion-body {
    font-size: 18px !important;
    color: #212528;

    @media (max-width: 768px) {
      font-size: 16px !important;
      color: #212528;
    }
  }


}