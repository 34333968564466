.editor-container {
  width: 100%;
  text-align: left;
}

.tui-editor-defaultUI {
  width: 100%;
  color: black;
  background-color: white;
}

.ProseMirror .placeholder {
  color: #343A3E;
  background-color: transparent;
}

.toastui-editor-defaultUI-toolbar {
  flex-wrap: wrap;
}

.CodeMirror-selected {
  background: blue !important;
}

.CodeMirror-focused .CodeMirror-selected {
  background: blue !important;
}