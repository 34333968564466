#faq-container .benefit-item {
    justify-content: center;
}

@media (min-width: 992px) {
    #faq-container .benefit-item {
        justify-content: start;
    }
}

#faq-container .header-nav-item {
    color: #ffffff !important;
}

#faq-container .accordion-item {
    border: 1px solid #fff;
}

#faq-container .accordion-item + .accordion-item {
    margin-top: 20px;
}

#faq-container .accordion-button {
    font-size: 22px;
    outline: none;
}

#faq-container .accordion-button:focus {
    outline: none;
    box-shadow: none;
}

#faq-container .accordion-button:not(.collapsed) {
    background-color: #017dfd !important;
    color: white !important;
}

#faq-container .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

#faq-container .accordion-button.collapsed:hover::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23017DFD'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

#faq-container .accordion-button:hover {
    color: #017dfd !important;
}

#faq-container .accordion-button:hover:not(.collapsed) {
    color: white !important;
}

@media (max-width: 768px) {
    #faq-container .accordion-button {
        font-size: 18px;
    }
}

#faq-container .accordion-body {
    font-size: 18px !important;
    color: #212528;
}

@media (max-width: 768px) {
    #faq-container .accordion-body {
        font-size: 16px !important;
        color: #212528;
    }
}

