#payment-manager-fragment-container {
  .ant-picker-input {
    input {
      text-align: center;
    }
  }
}

#plan-help {
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 264px;
  padding: 12px 16px;
  background-color: #212528 !important;
}